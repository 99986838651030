import React, { Component } from 'react';
import Layout from '../components/Layout.js';

class About extends Component {
  render() {
    return (
        <Layout>
          <div className="content">
            <div className="aux-info">
              <div className="with-margin">
                <h1>About the Comic</h1>
                <div className="aux-info-item">
                  <p>
                    If you could gain almost limitless power by risking probable death, would you?
                  </p>
                  <p>
                    Maydijira is a land forged by huge imbalances of power. Only a unique magical system—the Crown-Magic—brought peace and united the races by letting sovereigns negate all magic in their kingdoms. However, powerful groups unhappy with the system begin pursuing Shimurli, a girl gifted with unusual powers, in the hope that she can undermine the realm.
                  </p>
                  <p>
                    When the task to save the system falls to Shimurli and three others—an unconventional princess, a gifted but bitter swordsman, and a military engineer intimidated by his own ordinariness—each will have to overcome their personal demons and learn to work together to rise to the occasion... or face the consequences!
                  </p>
                  <h3>
                    Rating
                  </h3>
                  <p>I'm targeting an older YA audience, so in film terms, PG-13 is the goal. My personal view is that it's valuable to discuss mature themes in stories, although I'm bothered when creatives add "adult content" needlessly to raise sales. I prefer to avoid potentially distressing subject matter unless there's a thematic or plot reason to go there. I also think there's a difference between tastefully giving just enough information that the audience can understand what's going on, and portraying something in unflinching, graphic detail. But I have some mature themes and intense scenes planned, and it will fall to me as an artist to judge how to maintain that delicate balance.</p>
                </div>

                <h1>About the Author</h1>
                <div className="aux-info-item">
                  <p>Lyraluthuin (Larissa Smith) works as a web developer, has a film degree, and moonlights as an independent graphic novelist. Her various interests have imagination and a love of storytelling as the common denominators. She loves reading and movies and will compulsively devour anything traditionally animated (especially in the fantasy/adventure genres).  Her long-time dream is to help storyboard and animate powerful stories like the ones she grew up with and loved. When she's not writing javascript or doodling on her digital drawing tablet, she can sometimes be found adventuring through parts unknown, digging around cemeteries and archives for a long-lost ancestor's information, or wiping the floor with rival civs in an empire-building strategy game.</p>
                </div>
              </div>
            </div>
          </div>
        </Layout>
    );
  }
}

export default About;
